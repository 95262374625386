import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    [x: string]: any;

    constructor(
        private http: HttpService,
        public dialog: MatDialog,
    ) { }


    openDialog(componentName: any, height: any, width: any, dataModel: any) {
        return this.dialog.open(componentName, {
            width: width,
            height: height,
            data: dataModel,
            disableClose: true,
        });
    }

    signIn(data:any): Observable<any> {
        const data$ = new ReplaySubject<any>();
        this.http.post(
          environment.baseUrl + `admin/ssp/login`,
          data
        ).subscribe({
          next: (res) => {
            const data = res?.data;
            data$.next(data);
          },
          error: (err) => {
            data$.error(err)
          }
        })
    
        return data$;
      }


    refreshToken(): Observable<any> {
        // return this.httpClient.get(
        //   environment.baseUrl+`registration/token`
        // )

        const data$ = new ReplaySubject<any>();
        this.http.get(
            environment.baseUrl + `registration/token`
        ).subscribe({
            next: (res: any) => {
                const data = res;
                data$.next(data);
            },
            error: (err: any) => {
                data$.error(err)
            }
        })

        return data$;
    }
}
